
import { defineComponent, reactive, watchEffect, onMounted, watch, toRefs, computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { getQuickPhraseList } from '@/api/room'
import { useStore } from 'vuex'

const QuickPhase = defineComponent({
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        isH5: {
            type: Boolean,
            default: () => false
        }
    },
    setup(props: any, ctx: any) {
        const store = useStore()
        const data = reactive({
            show: false,
            quickphraseList: [],
            listLoading: false
        })

        const quickphraseTip: any = ref()

        watchEffect(() => {
            data.show = props.show
        })

        const toggleShow = () => {
            const main: any = document.getElementsByClassName('quickphrase-main-group')[0]
            main.style.display = main.style.display === 'none' ? 'flex' : 'none'
        }

        const toggleH5Show = () => {
            const parent = document.getElementById(props.parentID)
            const main = document.getElementsByClassName('quickphrase-H5-main')[0]
            if (props.isH5) {
                parent?.appendChild(main)
            }
        }

        onClickOutside(quickphraseTip, () => {
            const main: any = document.getElementsByClassName('quickphrase-main-group')[0]
            if (main) {
                main.style.display = 'none'
            }
        })
        const selectQuickPhrase = (item: any) => {
            ctx.emit('sendQuickPhrase', item.phrase)
            toggleShow()
        }
        const showquickphraseDialog = () => {
            store.commit('SHOWQUICKPHRASE_DIALOG', true)
            toggleShow()
        }
        watch(() => store.state.refresh_quickphrase,
            (newVal) => {
                if (newVal) {
                    console.log('执行更新快捷用语')
                    getQuickPhraseListData()
                }
            })
        const getQuickPhraseListData = () => {
            data.listLoading = true
            getQuickPhraseList().then(res => {
                console.log('得到快捷用语的结果', res)
                data.quickphraseList = res.data.phraseList
            }).finally(() => {
                data.listLoading = false
                store.commit('REFRESH_QUICKPHRASE', false)
            })
        }
        onMounted(() => {
            getQuickPhraseListData()
        })


        return {
            ...toRefs(data),
            toggleShow,
            selectQuickPhrase,
            showquickphraseDialog,
            quickphraseTip
        }
    }
})
export default QuickPhase
