
import { defineComponent, watchEffect,computed, reactive, toRefs,ref } from 'vue'
import { isUrl, JSONToObject } from '../utils/utils'
import constant from '../../constant'
import { useStore } from 'vuex'

export default defineComponent({
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props: any, ctx: any) {
        const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {}
        const data = reactive({
            data: {} as any,
            extension: {},
            isCustom: '',
            constant: constant
        })
        watchEffect(() => {
            data.data = props.data
            const {
                message: { payload }
            } = props.data
            data.isCustom = payload.data || ' '
            data.isCustom = JSONToObject(payload.data)
            if (payload.data === constant.typeService) {
                data.extension = JSONToObject(payload.extension)
            }
        })
        const getVoteTitle = (str:any)=>{
            let title = JSON.parse(str).title
            console.log('title--------',title);
            
            return title
        
        }
        const getDrawGameWord = (str:any)=>{
            let obj = JSON.parse(str).drawGameWord
            console.log('obj--------',obj);
            
            return obj?.name
        
        }
        const openLink = (url: any) => {
            window.open(url)
        }

        const handleClick = (data: any) => {
            let res = JSON.parse(data.message.payload.data)
            if(res.event==="voteResult"){
                let newData=JSON.parse(res.params.result)
                ctx.emit('openResultVote',newData)
            }else if(res.event==="drawGameResult"){
                let newData=JSON.parse(res.params.result)
                ctx.emit('openResultDraw',newData)
            }
        }

        const handleCallMessageIcon = () => {
            const callType = JSON.parse(JSON.parse(data?.data?.message?.payload?.data)?.data)?.call_type
            let className = ''
            switch (callType) {
                case 1:
                    className = 'icon-call-voice'
                    break
                case 2:
                    className = 'icon-call-video'
                    break
                default:
                    break
            }
            return className
        }

        const handleCallAgain = async () => {
            const callType = JSON.parse(JSON.parse(props?.data?.message?.payload?.data)?.data)?.call_type
            switch (data?.data?.message?.conversationType) {
                case (window as any).TUIKitTUICore.TIM.TYPES.CONV_C2C:
                    // eslint-disable-next-line no-case-declarations
                    const { flow, to, from } = data?.data?.message
                    if (to === from) break
                    try {
                        await (window as any)?.TUIKitTUICore?.TUIServer?.TUICallKit?.call({
                            userID: flow === 'out' ? to : from,
                            type: callType
                        });
                        (window as any)?.TUIKitTUICore?.isOfficial && VuexStore?.commit && VuexStore?.commit('handleTask', 6)
                    } catch (error) {
                        console.warn(error)
                    }
                    break
                case (window as any).TUIKitTUICore.TIM.TYPES.CONV_GROUP:
                    break
                default:
                    break
            }
        }

        return {
            ...toRefs(data),
            isUrl,
            openLink,
            handleClick,
            handleCallMessageIcon,
            handleCallAgain,
            getDrawGameWord,
            getVoteTitle
        }
    }
})
